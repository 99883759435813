import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Bar, BarChart } from 'recharts';
import moment from 'moment';

import '../../App.css';
import { StatsConfig } from '../../models/types';

interface DotProps {
  cx : number;
  cy : number;
  stroke : string;
  payload : any
  value : number;
}

const isSameDay = (dateToCheck : Date, actualDate : Date) => {
  return dateToCheck.getDate() === actualDate.getDate() 
   && dateToCheck.getMonth() === actualDate.getMonth()
   && dateToCheck.getFullYear() === actualDate.getFullYear()
}

const getDates = (from : Date, to : Date) => {
  let daysArr = [];
  let tempDate = from;

  tempDate.setHours(0,0,0,0);

  while (tempDate < to) {
      tempDate.setDate(tempDate.getDate() + 1);
      daysArr.push(new Date(tempDate));
  }

  return daysArr;
}

const CustomizedDot = (props : DotProps, color : string) => {
  const { cx, cy } = props;
  //key={`${cx},${cy}`}
  return (
    <svg x={cx} y={cy} width={4} height={4} fill={color} viewBox="0 0 1024 1024" >
      <path d="M 512 1009.984 c -274.912 0 -497.76 -222.848 -497.76 -497.76 s 222.848 -497.76 497.76 -497.76 c 274.912 0 497.76 222.848 497.76 497.76 s -222.848 497.76 -497.76 497.76 z z z z" />
    </svg>
  );
}

const HealthScatterPlot = (field : string, data : any[], config : StatsConfig) => {
  var stringToColor = (string : string, saturation = 85, lightness = 75) => {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    return `hsl(${(hash % 360)}, ${saturation}%, ${lightness}%)`;
  }

  if (field === 'Surveys') return null; 

  let unit = '';
  let color = stringToColor(field);

  if (data.length > 0) unit = data[0].data_unit;
  
  data = data.map(e => ({...e, start_time : new Date(e['start_time']).valueOf()}));

  let ticks = getDates(new Date(config.least_recent), new Date(config.most_recent))

  return <div className='graph-container' key={field} style={{overflow: 'visible'}}>
          <h4 className='graph-title'>{field}</h4>
          <ResponsiveContainer width={"100%"} height={210} key={field}>
              <LineChart width={730} height={250} data={data} 
                         margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" vertical={false}/>
                <XAxis dataKey="start_time" allowDataOverflow={false} type='number' ticks={ticks.map(t => t.valueOf())}
                       interval={0}
                       tickFormatter={(value, i) => i === 0 || i === ticks.length-1 ? moment(value).format('ll') : ''}
                       /* n.b. domain must be specified as a function otherwise rechart will ignore it! */
                       domain={[(dataMin : number) => (new Date(config.least_recent).valueOf()), 
                                (dataMax : number) => (new Date(config.most_recent).valueOf())]}                       
                       />
                <YAxis dataKey='data_value' name={'value'} /*unit={unit}*/ padding={{top : 20}}/>
                <Tooltip labelFormatter={(label) => [moment(label).format('lll')]}
                         itemStyle={{color : '#666666'}} contentStyle={{color : '#666666'}}
                         formatter={(value, _) => [`${value} ${unit}`]}
                         />
                {<Line type="monotone" stroke='#ffffff00' dataKey={'data_value'}
                      dot={(props : DotProps) => CustomizedDot(props, color)}/>}
              </LineChart>
            </ResponsiveContainer>
        </div>
}


const SurveyBarChart = (data : any[], config : StatsConfig) => {
  data = data.map(e => ({...e, day : new Date(e.day).setHours(0,0,0,0) }));

  let imputed_data = [];

  let earliest = new Date(config.least_recent)
  let latest = new Date(config.most_recent);
  let dates = getDates(earliest, latest);

  for (let date of dates) {
    const existing = data.find(obj => {
      return isSameDay(new Date(obj.day), new Date(date))
    });

    imputed_data.push(existing ?? {day : new Date(date), surveys : 0})
  }

  let ticks = getDates(new Date(config.least_recent), new Date(config.most_recent))


  return <div className='graph-container' key={'Surveys'}>
          <h4 className='graph-title'>Surveys</h4>
          <ResponsiveContainer width={"100%"} height={210} key={'Surveys'}>
              <BarChart width={730} height={250} data={imputed_data}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" vertical={false}/>
                {/*<XAxis dataKey="day" tickFormatter={(value) => moment(value).format('ll')}
                       domain={[(dataMin : number) => (new Date(config.least_recent).valueOf()), 
                                (dataMax : number) => (new Date(config.most_recent).valueOf())]}                       
                       allowDataOverflow={true} interval={2} type='number'/>*/}
                  <XAxis dataKey="day" allowDataOverflow={false} type='number' ticks={ticks.map(t => t.valueOf())}
                       interval={0}
                       tickFormatter={(value, i) => i === 0 || i === ticks.length-1 ? moment(value).format('ll') : ''}
                       domain={[(dataMin : number) => (new Date(config.least_recent).valueOf()), 
                                (dataMax : number) => (new Date(config.most_recent).valueOf())]}                       
                       />
                <YAxis dataKey='surveys' name={'surveys'} unit={''} allowDecimals={false} 
                       padding={{top : 20}}/>
                <Tooltip labelFormatter={(label) => [moment(label).format('ll')]}
                         itemStyle={{color : '#666666'}} contentStyle={{color : '#666666'}}
                         formatter={(value, _) => [`${value} surveys`]}
                         />
                <Bar dataKey="surveys" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
            {/*<hr className='graph-hr'/>*/}
        </div>
}


export { HealthScatterPlot, SurveyBarChart };
